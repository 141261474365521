const getDefaultOptions = (): RequestInit => {
  const token =
    localStorage.getItem('satellizer_token') || window.Socialie.tempUserToken;

  return {
    credentials: 'include',
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Api-Version': '2',
      'X-Socialie-Authorization': token ? `Bearer ${token}` : '',
      'X-Socialie-Suggester': window.location.pathname.split('/')[1],
    },
  };
};

const handleResponse = async (response: Response) => {
  if (response.ok || response.status === 422) {
    const textResponse = await response.text();
    return JSON.parse(textResponse || '{}');
  }

  if (response.status === 404) {
    return Promise.reject('Page Not Found');
  }

  return Promise.reject('HTTP error: ' + response.status);
};

const handleError = (error: Error) => {
  return Promise.reject(error);
};

export const SOCKET_SERVER_URL =
  import.meta.env.REACT_APP_SOCKET_URL ||
  import.meta.env.REACT_APP_API_SERVER_URL;

/**
 *
 * @param {string} endpoint a relative url for the api server (no leading slash)
 * @returns {Promise<never | Response>}
 */
export const get = (endpoint: string) => {
  const options = { ...getDefaultOptions(), method: 'GET' };

  return fetch(
    `${import.meta.env.REACT_APP_API_SERVER_URL}/${endpoint}`,
    options
  )
    .then(handleResponse)
    .catch(handleError);
};

/**
 *
 * @param {string} endpoint a relative url for the api server (no leading slash)
 * @param {{}} body
 * @param {string} method
 * @returns {Promise<never | Response>}
 */
export const post = (endpoint: string, body = {}, method = 'POST') => {
  const options = {
    ...getDefaultOptions(),
    method,
    body: JSON.stringify(body),
  };

  return fetch(
    `${import.meta.env.REACT_APP_API_SERVER_URL}/${endpoint}`,
    options
  )
    .then(handleResponse)
    .catch(handleError);
};
