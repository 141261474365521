import { routes, routeFor, flashMessage } from 'helpers';
import { useMutation } from '@apollo/react-hooks';
import ADD_BATCH_SUGGESTION_TO_EXISTING_BATCH from '../graphql/mutations/addSuggestionsToExistingBatch.graphql';
import { useHistory } from 'react-router-dom';

interface MutationData {
  addSuggestionsToBatch: {
    batchSuggestion?: { id: number | string };
    errors?: string;
  };
}
function useAddSuggestAttachmentsToExistingBatch(
  ids: (number | string)[] | number | string
) {
  const history = useHistory();
  const [addSuggestion, { loading: isLoading }] = useMutation<MutationData>(
    ADD_BATCH_SUGGESTION_TO_EXISTING_BATCH,
    {
      variables: { attachmentIds: ids },
      onCompleted: (data) => {
        if (data.addSuggestionsToBatch.errors) {
          console.warn(data.addSuggestionsToBatch.errors);
          flashMessage('Global__UnexpectedError', { style: 'error' });
        } else {
          const { id } = data.addSuggestionsToBatch.batchSuggestion!;
          history.push(routeFor(routes.draftBuilder.batch, id));
        }
      },
      onError: () =>
        flashMessage('Global__UnexpectedError', { style: 'error' }),
    }
  );

  return { isLoading, suggestAttachments: addSuggestion };
}

export default useAddSuggestAttachmentsToExistingBatch;
