import './init.ts';
import './sentry';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import App from 'App';
import 'focus-visible';
import 'polyfill-array-includes';
import { setupCharts } from 'styles';

// CSS Imports
import 'react-datepicker/dist/react-datepicker.css';
import 'styles/react-datepicker-overrides.css';
import 'tippy.js/dist/tippy.css';
import 'styles/tippy-overrides.css';
import 'draft-js/dist/Draft.css';
import 'draft-js-mention-plugin/lib/plugin.css';
import 'emoji-mart/css/emoji-mart.css';
import './index-build.css';

const FS = window['FS'];

if (FS) {
  window['_fs_ready'] = function () {
    const sessionUrl = FS.getCurrentSessionURL();
    if (sessionUrl) {
      Sentry.setTag('fullstory.session', sessionUrl);
    }
  };
}

// Init Chargebee
const Chargebee = (window as any).Chargebee;
if (Chargebee) {
  (window as any).Chargebee.init({
    site: import.meta.env.REACT_APP_CHARGEBEE_SITE,
    publishableKey: import.meta.env.REACT_APP_CHARGEBEE_PUBLISHABLE_KEY,
  });
}

setupCharts();

const rootEl = document.getElementById('root') as HTMLElement;

declare global {
  interface Window {
    Socialie: { tempUserToken?: string };
    FB: {
      login(
        callback: (response: {
          status: string;
          authResponse?: {
            accessToken: string;
            expiresIn: number;
            signedRequest: string;
            userID: string;
          };
        }) => void,
        options?: {
          config_id?: string;
          auth_type?: string;
          response_type?: string;
          override_default_response_type?: boolean;
          extras?: {
            featureType: string;
            sessionInfoVersion?: number;
            setup?: {
              solutionID?: string;
            };
          };
        }
      ): void;
    };
  }
}

window.Socialie = window.Socialie || {};
window.FB = window.FB || {};

ReactDOM.render(<App />, rootEl);
