import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';
import introspectionQueryResultData from './fragmentTypes.json';

const SERVER = import.meta.env.REACT_APP_API_SERVER_URL;
const ENDPOINT = '/graphql';

const httpLink = new HttpLink({ uri: SERVER + ENDPOINT });
const authLink = setContext((_, { headers }) => {
  const token =
    localStorage.getItem('satellizer_token') || window.Socialie.tempUserToken;

  return {
    headers: {
      ...headers,
      'X-Socialie-Authorization': token ? `Bearer ${token}` : '',
      'X-Socialie-Suggester': window.location.pathname.split('/')[1],
    },
  };
});

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

const cache = new InMemoryCache({ fragmentMatcher });

// TODO: Monkey-patching in a fix for an open issue suggesting that
// `readQuery` should return null or undefined if the query is not yet in the
// cache: https://github.com/apollographql/apollo-feature-requests/issues/1
cache['originalReadQuery'] = cache.readQuery;
cache.readQuery = (...args: any[]) => {
  try {
    return cache['originalReadQuery'](...args);
  } catch (err) {
    return undefined;
  }
};

const createApolloClient = new ApolloClient({
  cache,
  link: ApolloLink.from([authLink, httpLink]),
  connectToDevTools: true, // process.env.NODE_ENV !== 'production',
});

export default createApolloClient;
