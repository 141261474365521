import React from 'react';
import styled from 'styled-components';
import { grid, colors, sizes } from 'styles/theme';
import { useIsMobile } from 'hooks';
import Sticky from 'react-stickynode';
import RecordCount from './RecordCount';
import { useAppSelector } from 'redux/store';

type Props =
  | {
      loadedCount: number;
      totalCount?: number;
      // Some screens will have a max count, e.g. 1000, so even if there are
      // more than 1000 records in the database, the backend will only
      // return 1000. Then we can show the user that there are "1000+" records
      // if totalCount === maxCount.
      maxCount?: number;
      loading?: boolean;
      msgPrefix: string;
      selectedCount?: number;
      renderActions?: React.ReactNode;
      renderGlobalActions?: React.ReactNode;
      renderFilterPills?: React.ReactNode;
      renderSort?: React.ReactNode;
      selectAll?: () => void;
      selectNone?: () => void;
    }
  | { children: React.ReactNode };

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 100;
  border-bottom: 1px solid ${colors.grey7};
  background: ${colors.bodyBg};
  padding: 0 ${grid(3)};
  min-height: ${grid(6.5)};
`;

const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  height: ${grid(6)};

  > * {
    margin-right: ${grid(1)};

    &:last-child {
      margin-right: 0;
    }
  }
`;

const ActionsWrapper = styled(InnerWrapper)`
  margin-bottom: ${grid(1)};
  height: ${grid(4)};
`;

export default function StickyTopBar(props: Props) {
  const { isMobile } = useIsMobile();
  const isTopWarningBannerOpen = useAppSelector(
    (state) => state.ui.isTopWarningBannerOpen
  );
  const warningBannerHeight = isTopWarningBannerOpen
    ? sizes.TopWarningBanner.height
    : '0px';

  const top = isMobile
    ? parseInt(sizes.GlobalNavBar.height.default, 10) +
      parseInt(sizes.SearchAndFilterTopBar.height.default, 10) +
      parseInt(warningBannerHeight, 10)
    : parseInt(sizes.GlobalNavBar.height.tablet, 10) +
      parseInt(sizes.SearchAndFilterTopBar.height.tablet, 10) +
      parseInt(warningBannerHeight, 10);

  if ('children' in props) {
    return (
      <div className="-mx-3 -mt-3 mb-3">
        <Sticky enabled top={top} innerZ="100">
          <Wrapper>
            <InnerWrapper>{props.children}</InnerWrapper>
          </Wrapper>
        </Sticky>
      </div>
    );
  }

  const {
    totalCount,
    maxCount,
    selectedCount,
    renderActions,
    renderGlobalActions,
    renderFilterPills,
    renderSort,
    ...recordCountProps
  } = props;

  return (
    <div className="-mx-3 -mt-3 mb-3">
      <Sticky enabled top={top} innerZ="100">
        <Wrapper>
          <InnerWrapper>
            <RecordCount
              totalCount={totalCount}
              maxCount={maxCount}
              selectedCount={selectedCount}
              {...recordCountProps}
            />

            <div className="ml-auto flex items-center empty:hidden">
              {renderGlobalActions}
              {!!totalCount ? renderSort : null}
            </div>
          </InnerWrapper>

          {!!selectedCount && !!renderActions && (
            <ActionsWrapper>{renderActions}</ActionsWrapper>
          )}

          {renderFilterPills}
        </Wrapper>
      </Sticky>
    </div>
  );
}
