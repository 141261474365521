import { routes, routeFor, flashMessage } from 'helpers';
import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';

import gql from 'graphql-tag';

const ADD_BATCH_SUGGESTION = gql`
  mutation AddBatchSuggestion($attachmentIds: [ID!]) {
    addBatchSuggestion(
      attachmentIds: $attachmentIds
      actionableType: "Suggestion"
      platforms: ["facebook", "instagram", "twitter"]
    ) {
      errors
      batchSuggestion {
        id
      }
    }
  }
`;

interface MutationData {
  addBatchSuggestion: {
    batchSuggestion?: { id: number | string };
    errors?: string;
  };
}

export default function useSuggestAttachments(
  ids: (number | string)[] | number | string
) {
  const history = useHistory();
  const [addSuggestion, { loading: isLoading }] = useMutation<MutationData>(
    ADD_BATCH_SUGGESTION,
    {
      variables: { attachmentIds: ids },
      onCompleted: (data) => {
        if (data.addBatchSuggestion.errors) {
          console.warn(data.addBatchSuggestion.errors);
          flashMessage('Global__UnexpectedError', { style: 'error' });
        } else {
          const { id } = data.addBatchSuggestion.batchSuggestion!;
          history.push(routeFor(routes.draftBuilder.batch, id));
        }
      },
      onError: () =>
        flashMessage('Global__UnexpectedError', { style: 'error' }),
    }
  );

  return { isLoading, suggestAttachments: addSuggestion };
}
